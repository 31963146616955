<template>
  <v-row class="pa-4" align="center">
    <v-col cols="12">
      <h1>Leanbot IDE</h1>
      <h4>Version: {{version}}</h4>
      <p>
        Leanbot IDE is a web-based programing interface for programming microcontrollers,
        like an Arduino or ESP, and proxies process of compiling/uploading code from a remote server to the client.
        
      </p>
      <br>
      <h3>About Leanbot/Về Leanbot</h3>
      <p>
        Its first of the kind STEM Robotic toolkit that built for Learning by doing purpose
        that motivate and enhance Students STEM skills.
        
      </p>
    </v-col>
  </v-row>
</template>

<script>
import { mapMutations } from 'vuex';
import { version } from '../../package.json';

export default {
  data() {
    return {
      version,
      discordLink: 'https://discord.gg/FKQp7N4',
    };
  },
  methods: {
    ...mapMutations(['toggleDonateMenu']),
  },
};
</script>
